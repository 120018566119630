import styled from "styled-components";
import { NavLink } from 'react-router-dom';

export const LikiLink = styled(NavLink)`
    color: var(--color-primary);    
    display: block;
    text-decoration: none;
    width: 100%;
`;

export const Header1 = styled.div`
    font-size: 30px;
    margin: 0 0 1em;
`;

export const Header2 = styled.h2`
    font-size: 30px;
    font-weight: normal;
    line-height: 1.2em;
    margin: 0.2em 0;
    padding: 0.7em 0;
`

export const Header3 = styled.h3`
    border-bottom: 2px solid white;
    font-size: 30px;
    font-weight: normal;
    line-height: 1.2em;
    margin: 0.2em 0 0.8em;
    padding: 0.1em 0 0.3em;
    text-transform: uppercase;
`

export const Block = styled.div`
    display: flex;
    flex-direction: column;
    line-height: 2em;
    margin:  2% auto 0;
    padding: 0 0 3em;
    width: 100%;
    
    @media only screen and (max-width: 700px) {
        margin: 2% 0 0 0;
        width: 100%;
        text-align: justify;
    }
`;

export const BorderedBlock = styled(Block)`
    border-bottom: 2px solid white;
    padding-bottom: 2rem;
`;

export const NavButton = styled.button`
    background-color: white;
    border-color: var(--color-secondary);
    border-radius: 30px;
    margin: 1em 0;
    padding: 0.6em 0;
    width: 100%;

    a {
        color: var(--color-secondary);
        font-size: 30px;
    }
`;

export const Separator = styled.hr`
    border: 1px solid white;
`;

export const Center = styled.div`
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;

export const LinkButton = styled.div`
    background:none;
    box-shadow: none;
    font-size: 30px;
    outline:  none;
    text-align: center;
    text-transform: uppercase;

    a {
        color: white;
    }
`