import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { injectIntl } from "react-intl";

import logo from "./assets/minilogo.png";

const PageLayout = (props) => {
    const navigate = useNavigate();
    
    return (
        <div>
        <Wrapper>
            <LogoAndSearch>
                <Logo src = {logo} onClick={()=> {navigate(`/${props.intl.locale}`)}}/>
            </LogoAndSearch>
        </Wrapper>
        {props.children}
        </div>
    )
};

export default injectIntl(PageLayout);

const Wrapper = styled.div`
    align-items: center;
    background-color: var(--color-secondary);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    display: flex;
    flex-direction: row;
`;

const LogoAndSearch = styled.div`
    align-items: center;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    margin: 0 auto;
`;

const Logo = styled.img`
    margin: 5px 0;
    width: 100px;
`;