import { Route, Routes, BrowserRouter, useLocation, Navigate } from "react-router-dom";
import { useEffect } from "react";

import FAQ from './FAQ';
import PageLayout from './PageLayout';
import HomePage from "./HomePage";
import Landing from './Landing';
import SignUp from './SignUp';
import LangWrapper from './LangWrapper';

import { RouteNames, locales, translatedPath } from './i18n';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  const detectedLang = navigator.language.split(/[-_]/)[0] || 'en';

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Navigate to={"/" + detectedLang} />} />

        {Object.keys(locales).map((lang) => {
          return (
            <Route key={lang} path={`/${lang}`} element={<LangWrapper lang={lang} />}>
              <Route path="" element={<Landing />} />
              <Route path={translatedPath(RouteNames.Welcome, lang)} element={<PageLayout><HomePage /></PageLayout>} />
              <Route path={translatedPath(RouteNames.Signup, lang)} element={<PageLayout><SignUp /></PageLayout>} />
              <Route path={translatedPath(RouteNames.Faq, lang)} element={<PageLayout><FAQ /></PageLayout>}/>
            </Route>
          )
        })}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
