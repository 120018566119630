import { FormattedMessage, useIntl } from 'react-intl';
import styled from "styled-components";

import { LinkButton, Header3, NavButton, Block, Center } from "./components";
import { RouteNames, TranslatedLink } from './i18n';

const HomePage = () => {
    const intl = useIntl();
    return(
        <Center>
            <Wrapper>
                <Block>
                <Header3><FormattedMessage id="app.HomePage.who_title" /></Header3>
                <p><FormattedMessage id="app.HomePage.who_body1" /></p>
                </Block>

                <Block>
                <Header3><FormattedMessage id="app.HomePage.how_title" /></Header3>
                <p><FormattedMessage id="app.HomePage.how_body1" /></p>
                </Block>

                <Block>
                    <NavButton>
                        <TranslatedLink to={RouteNames.Signup}>
                            <FormattedMessage id="app.HomePage.BecomeAMember" />
                        </TranslatedLink>
                    </NavButton>
                </Block>
            </Wrapper>
        </Center>
    )
};

export default HomePage;

const FloorPlan = styled.img`
    width: 100%;
`;

const Wrapper = styled.div`
    border: none;
    border-radius: 20px;
    margin: 5% 0 5% 0;
    width: 50%;

    @media only screen and (max-width: 700px) {
        width: 90%
    }
`;

