import { FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { LikiLink, Header3, Block, Center } from "./components";
import { TranslatedLink } from './i18n';

const FAQ = () => {
    return(
    <Center>
        <Wrapper>
            <Paragraph>
                <Header><FormattedMessage id="app.FAQ.Title" /></Header>
            </Paragraph>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.What_title" /></Header3>
                <FormattedMessage id="app.FAQ.What_body" />
            </Block>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.Who_title" /></Header3>
                <FormattedMessage id="app.FAQ.Who_body" />
            </Block>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.Friends_title" /></Header3>
                <FormattedMessage id="app.FAQ.Friends_body" />
            </Block>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.Propose_title" /></Header3>
                <FormattedMessage id="app.FAQ.Propose_body" />
            </Block>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.GA_title" /></Header3>
                <FormattedMessage id="app.FAQ.GA_body" />
            </Block>
            <Block>
                <Header3><FormattedMessage id="app.FAQ.Keyholder_title" /></Header3>
                <FormattedMessage id="app.FAQ.Keyholder_body" />
            </Block>
            <Paragraph>
                <NavButton> 
                    <TranslatedLink to=""> 
                    <FormattedMessage id="app.FAQ.BackButton_1" /><div><FormattedMessage id="app.FAQ.BackButton_2" /></div>
                    </TranslatedLink>
                </NavButton>
            </Paragraph>
        </Wrapper>
    </Center>
    )
};
export default FAQ;

const Wrapper = styled.div`
    background-color: var(--color-secondary);
    border-radius: 20px;
    border: 10px solid white;
    margin: 5%;
    padding: 2% 4% 2% 4%;
    width: 100%;
    width: 50%;

    @media only screen and (max-width: 700px) {
        width: 90%;
    }   
`;


const Paragraph = styled.div`
    margin:  2% 0 0 0;
    max-width: 100%;
    padding: 2% 0 ;
    a{
        text-decoration: none;
        color: var(--color-primary);
    }
`;

const Header = styled.div`
    background-color: var(--color-tertiary);
    border-radius: 5px;
    border: 2px solid white;
    color: white;
    font-size: 25px;
    margin: 0 0 6% 0;
    padding: 5% 0;
    text-align: center;
`;

const NavButton = styled.button`
    background-color: white;
    border-color: var(--color-secondary);
    border-radius: 30px;
    margin: 6% 0 6% 0;
    padding: 2% 0;
    width: 100%;

    a {
        color: var(--color-secondary);
        font-size: 30px;d
    }
`;
