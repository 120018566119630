import { useIntl, FormattedMessage } from 'react-intl';
import styled from "styled-components";
import { LikiLink, Header2, Header3, Block, NavButton, BorderedBlock, Center } from "./components";
import { ExternalLink, RouteNames, TranslatedLink } from './i18n';

const YoticeSignUp = () => {
  const intl = useIntl();

  const email = intl.messages["app.SignUp.Email"]; // just the word "E-mail"
  const list_id = intl.messages["app.SignUp.Newsletter_ID"];

  return (
    <div>
      <Block>
        <Header><a id="yotice" /><FormattedMessage id="app.SignUp.Newsletter_title" /></Header>
        <FormattedMessage id="app.SignUp.Newsletter_body" />
        <form method="post" action="https://list.yota.space/subscription/form">
          <input type="hidden" name="nonce" />
          <input id="fe1f2" type="hidden" name="l" value={`${list_id}`} />
          <Center>
            <input type="email" name="email" required placeholder={`${email}`} />
            <button><FormattedMessage id="app.SignUp.Subscribe" /></button>
          </Center>
        </form>
      </Block>
    </div>
  )
}

const Charter = () => {
    return (
             <><a id="charter" />
                <Block>
                    <Header><FormattedMessage id="app.SignUp.Title" /></Header>
                    <FormattedMessage id="app.SignUp.Intro" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Participation_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Participation_body" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Respect_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Respect_body" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Kinship_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Kinship_body" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Magic_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Magic_body" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Safety_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Safety_body" />
                </Block>
                <Block>
                    <Header3><FormattedMessage id="app.SignUp.Communication_title" /></Header3>
                    <FormattedMessage id="app.SignUp.Communication_body" />
                </Block>
                <Block>
                    <Conclusion>
                      <p><FormattedMessage id="app.SignUp.Conclusion1" /></p>
                      <p><FormattedMessage id="app.SignUp.Conclusion2" /></p>
                    </Conclusion>
                </Block>
              </>
    )
}

const Page = () => {
    return(
        <Center>
            <Wrapper>
              <Charter />
            </Wrapper>
            <Wrapper>
              <YoticeSignUp />
            </Wrapper>
            <WrapperNoBorder>
            <Block>
                <NavButton>
                    <TranslatedLink to={RouteNames.Welcome}>
                        &#8592; <FormattedMessage id="app.SignUp.BackButton" />
                    </TranslatedLink>
                </NavButton>
            </Block>
            </WrapperNoBorder>
        </Center>
    )
};
export default Page;

const Wrapper = styled.div`
    background-color: var(--color-secondary);
    border: 10px solid white;
    border-radius: 20px;
    margin: 2em 0;
    padding: 0 2em;
    padding-bottom: 2em;
    width: 60%;
    
    @media only screen and (max-width: 700px) {
        border: none;
        padding: 0;
        width: 90%;
    }   
`;

const WrapperNoBorder = styled.div`
    background-color: var(--color-secondary);
    margin: 2em 0;
    padding: 0 2em;
    padding-bottom: 2em;
    width: 60%;

    @media only screen and (max-width: 700px) {
        border: none;
        padding: 0;
        width: 90%;
    }
`;

const Header = styled.div`
    border-radius: 5px;
    color: white;
    font-size: 25px;
    padding: 5% 0;
    text-align: center;
    text-transform: uppercase;
`;

const Conclusion = styled.div`
    background-color: #fff;
    color: var(--color-primary);
    font-size: 20px;
    margin: 4% 0 2% 0;
    padding: 5%;
    text-align: center;
`;
