import { Outlet } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { locales } from './i18n';

const LangWrapper = ({ lang }) => { 
    return (
        <IntlProvider locale={lang} messages={locales[lang].messages}>
            <Outlet />
        </IntlProvider>
    )
}

export default LangWrapper;
