import styled from "styled-components";
import { injectIntl } from "react-intl";

import { LikiLink, Block, LinkButton } from "./components";
import logo from "./assets/biglogo.png";
import { RouteNames, translatedPath } from "./i18n";

const Landing = ({ intl }) => {
    let languageLinks = [
        <LikiLink to={`/en/${translatedPath(RouteNames.Welcome, "en")}`} key="en">
            Enter
        </LikiLink>,
        <LikiLink to={`/fr/${translatedPath(RouteNames.Welcome, "fr")}`} key="fr">
            Entrez
        </LikiLink>
    ];
    
    if (intl.locale === "fr") {
        languageLinks = languageLinks.reverse();
    }

    return(
        <BigLogo>
            <LinkButton>
                {languageLinks}
            </LinkButton>
        </BigLogo>
    )
};

export default injectIntl(Landing);

const BigLogo = styled(Block)`
    background-image: url(${logo});
    background-position: center;
    background-repeat: no-repeat;
    background-size: 50%;
    height: 100vh;
    margin: 0;
    width: 100%;

    @media only screen and (max-width: 700px) {
        background-size: contain;
        margin: 0 auto;
        width: 90%;
    }

    > div {
        position: fixed;
        left: 0;
        bottom: 5vh;
        width: 100%;
        text-align: center;

        > a {
            font-size 1.5em;

            &:last-child {
                font-size: 0.5em;
            }
        }
    }
`
